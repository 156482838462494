<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            v-if="$can('delete', 'certificate')"
            class="d-flex align-items-end justify-content-end mb-1">
            <b-button variant="primary" class="mr-1" @click="DeleteRows">
              Delete Selected Course
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="4"
            md="4"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            ">
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="8"
            md="8"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..." />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="categoriesList"
        select-mode="multi"
        :sort-by.sync="sort"
        selectable
        @row-selected="onRowSelected"
        show-empty
        empty-text="No matching records found">
        <!-- Column: Status -->

        <!-- Column: Actions -->

        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <a>
            <router-link
              :to="`/certificates/update-certificates/${data.item.id}`"
              style="margin: 0px 12px">
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>
        
          <a  v-if="$can('delete', 'certificate')" role="button" @click="DeleteItem(data.item.id)">
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, watch, computed, reactive } from "@vue/composition-api";
import Vue from "vue";

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,

    vSelect,
  },
  setup() {
    const categoriesList = ref([]);
    const perPageOptions = [25, 50, 100, 200];
    const perPage = ref(10);
    const totalCategories = ref(0);
    const currentPage = ref(1);
    const refCourseListTable = ref("");
    const searchQuery = ref("");
    const allItem = ref([]);

    const selectedRow = ref([]);
    const onRowSelected = (items) => {
      selectedRow.value = items;
      console.log("selectedRow", selectedRow);
    };
    const DeleteRows = () => {
      console.log("se", selectedRow.value);
      let data = [];
      selectedRow.value.forEach((el) => {
        data.push(el.id);
      });
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          console.log("data", data);
          store
            .dispatch("blog/DeleteSelectedCertificate", data)
            .then((response) => {
              getCertificate();
              Vue.swal({
                title: "Certificate Deleted ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };

    const tableColumns = [
      { key: "id", lanel: "ID", sortable: true },
      { key: "first_name", label: "First Name", sortable: true },
      { key: "middle_name", label: "Middle Name", sortable: true },
      { key: "last_name", label: "Last Name", sortable: true },
      { key: "ID_certificate", label: "Certifcate Number", sortable: true },
      { key: "course_type", label: " Course Type", sortable: true },

      { key: "actions" },
    ];
    const sort=ref('')
    const SortArray=[]
    watch([currentPage, perPage, searchQuery,sort], () => {
      getCertificate();
    });
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      };
    });
   
    const meta = reactive({});
    const getCertificate = () => {
      let data = null;
    
      if (!SortArray.includes(sort.value) && sort.value != "") {
        SortArray.push(sort.value);
        data = SortArray.toString().replace("[", "");
      }

      store
        .dispatch("blog/allcertificate", {
          "filter[search]": searchQuery.value,
          sort: data,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then((response) => {
          console.log("response", response.data);
          categoriesList.value = response.data.data.data;
          totalCategories.value = response.data.data.total;
          console.log("totalCategories", totalCategories);
        });
    };
    getCertificate();
    const DeleteItem = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("blog/Deletecertificate", id).then((response) => {
            getCertificate();
            Vue.swal({
              title: "Certificate Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };
    return {
      tableColumns,
      categoriesList,
      DeleteItem,
      meta,
      getCertificate,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      searchQuery,
      allItem,
      onRowSelected,
      selectedRow,
      DeleteRows,
      refCourseListTable,
      SortArray,
      sort,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
