<template>
  <b-card title="Certificates">
    <b-tabs>
        <b-tab  :active="currentRouteName=='add-certificates'"  @click="$router.push('/certificates/add-certificates')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Certificate</span>
        </template>
<!-- <AddCertificates></AddCertificates> -->
      </b-tab>
      <b-tab  :active="currentRouteName=='all-certificates'"  @click="$router.push('/certificates/all-certificates')" >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>All Certificates</span>
        </template>
<!-- <ViewCertificates></ViewCertificates> -->
      </b-tab>
  
     
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <AddCourse v-if="route.name === 'index-certificate' ||route.name === 'add-certificates' "  />
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import ViewCertificates from './ViewCertificates.vue';
import AddCertificates from './AddCertificates.vue';


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    AddCertificates,
    
    ViewCertificates
},
  data() {
    return {
   
    }
  },
     
  computed: {
    currentRouteName() {
        return this.$route.name;
    }}

}
</script>
