<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        New Certificate
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
        ref="form">
        <b-row>
          <b-col md="3">
            <b-form-group
              label=" First Name of Certified
"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required">
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.first_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label=" Middle Name of Certified
"
              label-for="blog-edit-slug"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Middle Name"
                rules="">
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.middle_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Last Name of Certified 
"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required">
                <b-form-input
                  v-model="addCourseForm.last_name"
                  id="video_link" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Unique Number of Certified 
"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Unique Number "
                rules="required">
                <b-form-input
                  v-model="addCourseForm.ID_certificate"
                  id="video_link" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Course Type"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Course Type"
                rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.course_type"
                  label="label"
                  :options="CourseType"
                  @input="getDeticatedCourse(addCourseForm.course_type)"
                  :reduce="(val) => val.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="addCourseForm.course_type != 'Dedicated' && addCourseForm.course_type!=='Custom'">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.category_id"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="addCourseForm.course_type!=='Custom'">
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Course" rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.course_id"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id">
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="addCourseForm.course_type == 'Custom'">
            <b-form-group
              label="Course Custom name
"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Course Custom name"
                rules="required">
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.course_custom_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Certificate/Image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0" />
                </b-media-aside>
                
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                addCourseForm.image=''
                ">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2">
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required">
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">Certificate PDF</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <b-media-body>
                  <b-card-text class="my-50"> </b-card-text>
                  <div class="d-inline-block">
                    <b-form-group
                      label="Certificate PDF "
                      label-for="blog-edit-title"
                      class="mb-2">
                      <validation-provider
                        #default="{ errors }"
                        name="Certificate PDF "
                        rules="required">
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCourseForm.pdf"
                          accept=".pdf"
                          placeholder="Choose file"
                          @input="inputImageRenderer" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const form = ref();
    const refPreviewE2 = ref(null);

    const Tabimage = ref("media/svg/files/blank-image.svg");
    const categoriesList = ref([]);
    const coursesList = ref([]);
    const getDeticatedCourse = (id) => {
      console.log("id", id);
      if (id == "Dedicated") {
        store.dispatch("othercourses/Allothercourses").then((response) => {
          console.log("Details", response.data);
          coursesList.value = response.data.data;
        });
      }
    };

    const getCoursesByCategory = (id) => {
      addCourseForm.course_id = "";
      let online=null
      if(addCourseForm.course_type=='Classic'){
        online=0
      }
      else{
        online=1
      }
      store
        .dispatch("courses/getAllCoursesList", {
          "filter[category_id]": id,
          "filter[online]":online
        })
        .then((response) => {
          console.log("Details", response.data);
          coursesList.value = response.data;
        });
    };
    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      categoriesList.value = response.data.data;
    });
    const CourseType = ref([
      {
        label: "Classic",
        id: "Classic",
      },
      {
        label: "Dedicated",
        id: "Dedicated",
      },
      {
        label: "Online",
        id: "Online",
      },
      {
        label: "Custom",
        id: "Custom",
      },
    ]);
    const addCourseForm = reactive({
      first_name: "",
      last_name: "",
      course_id: "",
      image: [],
      header_image: [],
      alt_image: "",
      alt_header_image: "",
      ID_certificate: "",
      middle_name: "",
      course_type: "",
      pdf: "",
      course_custom_name: "",
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("first_name", addCourseForm.first_name);
          formData.append("last_name", addCourseForm.last_name);
          formData.append("course_id", addCourseForm.course_id);
          formData.append("ID_certificate", addCourseForm.ID_certificate);
          formData.append("middle_name", addCourseForm.middle_name);
          formData.append("image", addCourseForm.image);
          formData.append(" course_type", addCourseForm.course_type);
          formData.append("pdf", addCourseForm.pdf);
          formData.append(
            "course_custom_name",
            addCourseForm.course_custom_name
          );

          console.log("formData", formData);
          store
            .dispatch("blog/addcertificate", formData)
            .then((response) => {
              addCourseForm.course_type=''
              addCourseForm.category_id=''
              addCourseForm.course_id=''
              // refPreviewEl.value.src ="media/svg/files/blank-image.svg"
              refPreviewE2.value.src = "media/svg/files/blank-image.svg";
              form.value.reset();
              Vue.swal({
                title: "Certificate Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      CourseType,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      getCoursesByCategory,
      coursesList,
      categoriesList,

      form,
      save,
      required,
      email,
      getDeticatedCourse,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
